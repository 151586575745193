@import "primeicons/primeicons.css";

body {
  margin: 0;
  user-select: none;
}

.error-message {
  color: #d9534f;
  font-weight: 400;
}
